import { Close } from "@material-ui/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import apiFn from "../../../asset/apiClass";
import constants from "../../../asset/constants";
import style from "../../../asset/style";
import util from "../../../asset/util";
import Basic from "../../../common/frame/Basic";
import UiBtn from "../../../uiComponents/UiBtn/UiBtn";
import UiSearchBox from "../../../uiComponents/UiSearch/UiSearchBox";
import { UiSearchList, UiSearchSection } from "../../../uiComponents/UiSearchList/UiSearchList";
import RegiForm from "./RegiForm";
import Register_Five_style from "./Register_Five_style";
import Register_One_style from "./Register_One_style";

const AcademySection = ({ c_data, setC_data, isTeacher, data }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: 12,
        padding: "5px 12px",
        margin: "5px",
        color: "white",
        fontWeight: "bold",
        borderRadius: 20,
        background: isTeacher ? style.common.t_color : style.common.s_color,
      }}
    >
      {/* 시대인재 (대치) */}
      {data}
      <Close
        style={{
          fontSize: "14px",
          marginLeft: "20px",
          fontWeight: "normal",
        }}
        onClick={() => {
          let obj = c_data;
          delete obj[data];
          setC_data({
            ...obj,
          });
        }}
      />
    </div>
  );
};

const Register_Five = ({ userInfo }) => {
  let params = useParams();
  let navigate = useNavigate();

  const [searchClassList, setSearchClassList] = useState([]);
  const [search, setSearch] = useState("");

  const [isTeacher, setIsTeacher] = useState(false);
  const [select_class, setSelect_class] = useState("");

  const [c_data, setC_data] = useState({});

  const [s_classData, setS_classData] = useState({});

  const [route, setRoute] = useState(0);

  const apiPro_read = async () => {
    try {
      if (search.length !== 0) {
        const classList = await (
          await fetch(
            `${constants.apiUrl}/class/?classType=1&isClassroomRequired=true&className=${search}&${constants.classroomId ? `classroomId=$${constants.classroomId}` : ""}`
          )
        ).json();
        setSearchClassList(classList);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_regi = async () => {
    try {
      let api = async (data) => {
        let sendMsg = {};
        await apiFn.comApi({
          state: {
            table: "member_class",
            sendMsg: {
              type: params.who === "teacher" ? "suup_apply" : "student_apply",
              orderMid: userInfo["id"],
              targetMid: userInfo["id"],
              cid: data,
              channel: constants.channelId,
            },
            ins: [
              {
                mid: userInfo["id"],
                cid: data,
                type: params.who == "teacher" ? 6 : 0,
                status: 0,
                reg_date: 0,
              },
            ],
          },
        });
      };
      let promiseArray = Object.keys(c_data).map((v, i) => api(c_data[v]));
      await Promise.all(promiseArray);
      alert("수업 신청이 완료됐어요.");
      if (constants.isAcademy) {
        navigate(`/`);
      } else {
        navigate(`/message/${params["who"]}/home`);
      }
    } catch (e) {
      alert("수업 신청 중 오류가 발생 했어요.");
      // navigate(`/message/${params["who"]}/home`)
    }
  };

  useEffect(() => {
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
    if (params["type"] == "l") {
      setRoute(`/regiFive/${params["who"]}/c`);
    } else if (params["type"] == "c") {
      // setRoute(`/message/${params["who"]}/home`)
      setRoute(0);
    }
  }, [params["who"]]);
  useEffect(() => {
    if (!constants.isAcademy) {
      return;
    }
    const checkAndApplyLecture = async () => {
      try {
        let data = await apiFn.comApi({
          state: {
            table: "class",
            classId: constants.classroomId,
            page: 1,
            amount: 9999,
            sort: "reg_date desc",
            where: {
              "HIQDB_class.pid": `= ${constants.classroomId}`,
            },
            ref: [`B.name as academyName|HIQDB_class B on B.id=HIQDB_class.pid`],
            count: ["HIQDB_member_class.mid:cid:id|and countTable.type ='0'|lcnt"],
          },
        });

        if (data.list.length > 1) {
          setSearchClassList(data.list);
        } else {
          const cid = data.list?.[0]?.id;

          const result = await apiFn.comApi({
            state: {
              table: "member_class",
              sendMsg: {
                type: params.who === "teacher" ? "suup_apply" : "student_apply",
                orderMid: userInfo["id"],
                targetMid: userInfo["id"],
                cid,
                channel: constants.channelId,
              },
              ins: [
                {
                  mid: userInfo["id"],
                  cid,
                  type: params.who == "teacher" ? 6 : 0,
                  status: 0,
                  reg_date: 0,
                },
              ],
            },
          });
          alert("가입 요청을 했습니다.\n승인을 기다려주세요");
          navigate(`/`);
        }
      } catch (e) {}
    };
    if (search.length === 0) {
      checkAndApplyLecture();
      util.removeLocalStorage();
    }
  }, [search, constants.isAcademy]);
  return (
    <Basic
      // route={0}
      route={
        route
        // params["type"] == "c" ?
        // `/message/${params["who"]}/home`
        // :
        // `/regiFive/${params["who"]}/c`
      }
      backEvent={() => {
        if (params["type"] == "c") {
          let isOk = window.confirm(
            `수업 가입 신청 없이 가입 절차를 완료하시겠어요?\n(이후에도 수업 메뉴를 통해 수업 가입 신청을 하실수가 있어요.)`
          );
          if (isOk) {
            navigate(`/message/${params["who"]}/home`);
          } else {
            // route = 0
            // setRoute(0)
            // navigate(`/regiFive/${params["who"]}/c`)
            // return false
          }
        }
      }}
    >
      <RegiForm
        numb={5}
        isTeacher={isTeacher}
        title={
          <div
            style={{
              display: "flex",
            }}
          >
            수업선택
          </div>
        }
      />
      {Object.keys(c_data).length > 0 && (
        <div style={Register_Five_style.s_c}>
          {isTeacher ? "등록을 신청할 학원" : "등록을 신청할 수업"}
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "5px 0px",
              flexWrap: "wrap",
            }}
          >
            {Object.keys(c_data).map((v, i) => (
              <AcademySection
                data={v}
                isTeacher={isTeacher}
                setC_data={setC_data}
                c_data={c_data}
              />
            ))}
          </div>
        </div>
      )}
      {s_classData["id"] && <UiSearchSection data={s_classData} isTeacher={isTeacher} />}
      <div
        style={{
          ...Register_Five_style.s_c,
          marginTop: "10px",
        }}
      >
        <UiSearchBox
          isTeacher={isTeacher}
          title={"수업 검색"}
          fnClick={() => {
            apiPro_read();
          }}
          fnChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      {searchClassList.length > 0 && (
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <UiSearchList
            title={search + " 검색결과"}
            title_add={`(${searchClassList.length}건)`}
            isTeacher={isTeacher}
            form={1}
            data={searchClassList}
            fnClick={(d) => {
              setC_data((prev) => {
                if (Object.keys(prev).filter((className) => className === d.name).length === 0) {
                  return { ...prev, [d.name]: d.id };
                } else return prev;
              });
            }}
          />
        </div>
      )}

      <div style={Register_One_style.b_c}>
        {!constants.isAcademy ? (
          <>
            <UiBtn
              title="나중에 할래요"
              fnClick={() => {
                let isOk = window.confirm(
                  `수업 가입 신청 없이 가입 절차를 완료하시겠어요?\n(이후에도 수업 메뉴를 통해 수업 가입 신청을 하실 수 있어요.)`
                );
                if (isOk) {
                  navigate(`/message/${params["who"]}/home`);
                } else {
                  // route = 0
                  // setRoute(0)
                  // navigate(`/regiFive/${params["who"]}/c`)
                  // return false
                }
                // navigate("/regiFive/"+params["who"])
              }}
            />
            <UiBtn
              btnStyle={{
                ...Register_One_style.b,
                borderColor: style.common.borderColor,
                backgroundColor: isTeacher ? style.common.t_color : style.common.s_color,
                // backgroundColor : "rgb(200,200,200)"
              }}
              title={"가입 완료"}
              fnClick={() => {
                let isOk;
                if (Object.keys(c_data).length == 0) {
                  alert(
                    "수업은 1개 이상은 꼭 선택해야합니다. \n 수강신청 할 수업을 선택해 주세요."
                  );
                  return;
                }
                if (isTeacher) {
                  isOk = window.confirm(
                    `${Object.keys(c_data).length}개의 수업` + constants.confirm.cf_4
                  );
                } else {
                  isOk = window.confirm(constants.confirm.cf_5);
                }
                if (isOk) {
                  apiPro_regi();
                  navigate("/");
                }
              }}
            />
          </>
        ) : (
          <UiBtn
            btnStyle={{
              ...Register_One_style.b,
              borderColor: style.common.borderColor,
              backgroundColor: isTeacher ? style.common.t_color : style.common.s_color,
              // backgroundColor : "rgb(200,200,200)"
            }}
            title={"가입 완료"}
            fnClick={() => {
              let isOk;
              if (Object.keys(c_data).length == 0) {
                isOk = window.confirm("선택된 수업이 없어요. 계속 진행하시겠어요?");
                if (isOk) {
                  navigate(`/message/${params["who"]}/home`);
                }
              } else {
                if (isTeacher) {
                  isOk = window.confirm(`[${select_class}]` + constants.confirm.cf_4);
                } else {
                  isOk = window.confirm(constants.confirm.cf_5);
                }
                if (isOk) {
                  apiPro_regi();
                  // navigate("/regiAfter/"+params["who"])
                }
              }
            }}
          />
        )}
      </div>
    </Basic>
  );
};

export default Register_Five;
