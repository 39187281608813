import { ReactComponent as SearchIcon } from "asset/images/icon/search-normal.svg";
import { getUser } from "asset/storage";
import { FontStyleMap } from "components/atoms/Font";
import React, { useMemo } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { WhoType } from "types/enum";

const LectureHeader = () => {
  const navigate = useNavigate();
  const userInfo = getUser();
  const isTeacher = useMemo(() => userInfo?.type === WhoType.teacher, [userInfo]);
  return (
    <Container>
      수업영상
      <a
        onClick={() => navigate("/lecture/search")}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
          cursor: "pointer",
        }}
      >
        <SearchIcon />
        <span
          style={{
            fontSize: "12px",
          }}
        >
          수업 검색
        </span>
      </a>
    </Container>
  );
};

const Container = styled.div`
  ${FontStyleMap["KR/bold/$KR.TM.BOL.24"]}
  color: var(--neutral-color-natural-70, var(--Default-Gray-90, #262626));
  position: sticky;
  top: 0;
  background-color: var(--neutral-color-natural-10);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0 8px;
  z-index: 1;
`;

export default LectureHeader;
