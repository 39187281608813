import { useAddFavorite, useClassDetailInfo, useRemoveFavorite } from "api/classDetail";
import constants from "asset/constants";
import { getUser } from "asset/storage";
import util from "asset/util";
import dayjs from "dayjs";
import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import AdditionalFile from "./AdditionalFile";
import {
  Download,
  Favorite,
  FavoriteBorder,
  LinkOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import store from "../../../../asset/store";

const VideoSubInfo = () => {
  const params = useParams();
  const parameters = {
    boardId: params["id"],
  };
  const userInfo = getUser();
  const { data } = useClassDetailInfo(parameters);
  const { mutate: addFavorite } = useAddFavorite(parameters);

  const videoData = useMemo(() => data?.getBoard?.boards[0], [data]);
  const { mutate: removeFavorite } = useRemoveFavorite({
    bid: `${videoData.id}`,
    wid: videoData.mid,
  });
  const likeCnt = useMemo(
    () => videoData?.logs.filter((item) => item.btype === 2)?.length ?? 0,
    [videoData]
  );
  const readCnt = useMemo(() => videoData?.logs?.length ?? 0, [videoData]);

  const isFavorite = useMemo(
    () =>
      videoData?.logs.filter((item) => item.btype === 2 && item.wid === userInfo?.id)?.length > 0,
    [videoData]
  );

  const handleClipboard = useCallback(async () => {
    if (window.navigator.share) {
      window.navigator.share({
        title: videoData.title, // 공유될 제목
        text: videoData.content, // 공유될 설명
        url: `${constants.redirect_url}classdetail/student/${params.id}/lecture_video`, // 공유될 URL
      });
    } else {
      await util.copyClip(
        `${constants.redirect_url}classdetail/student/${params.id}/lecture_video`
      );
      alert("강의 영상 주소가 클립보드에 복사 됐습니다");
    }
  }, [util.copyClip, params]);

  const handleRemoveFavorite = useCallback(() => {
    if (!userInfo) {
      return;
    }
    if (isFavorite) {
      removeFavorite();
    } else {
      addFavorite();
    }
  }, [parameters]);

  const [expanded, setExpanded] = useState<boolean>(false);

  const isDownloadBtn = useMemo(() => videoData?.mid === userInfo?.id, [videoData, userInfo]);

  const videoDownloadHref = useMemo(() => {
    const fileName = `${videoData.id}.mp4`;
    const filePath = `${constants.video_bucket}/board/${videoData.id}.mp4`;
    const videoUrl = store.isMobile()
      ? `http://native_download?filename=${fileName}?filepath=${filePath}`
      : filePath;

    return videoUrl;
  }, [videoData]);

  return (
    <>
      <Container>
        <SubContainer>
          <GrayFont>
            {videoData?.reg_date && dayjs.unix(videoData?.reg_date).format("YYYY.MM.DD")}
          </GrayFont>
          <IconContainer onClick={handleClipboard}>
            <LinkOutlined />
          </IconContainer>
          {isDownloadBtn && (
            <IconContainer>
              <a download href={videoDownloadHref}>
                <Download />
              </a>
            </IconContainer>
          )}
          <IconContainer onClick={handleRemoveFavorite}>
            {isFavorite ? <Favorite /> : <FavoriteBorder />}
            <GrayFont>{likeCnt}</GrayFont>
          </IconContainer>
          <IconContainer>
            <VisibilityOutlined />
            <GrayFont>{readCnt}</GrayFont>
          </IconContainer>
        </SubContainer>
        <SubContainer>
          <AdditonalFileContainer
            onClick={(e) => {
              setExpanded((prev) => !prev);
            }}
          >
            첨부파일{videoData.files.length > 0 && `(+${videoData.files.length})`}
          </AdditonalFileContainer>
        </SubContainer>
      </Container>
      <AdditionalFile expanded={expanded} />
    </>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const GrayFont = styled.span`
  color: #8b8b8b;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 2px;
  & svg {
    color: #8b8b8b;
    font-size: 16px;
  }
`;

const AdditonalFileContainer = styled.button`
  border: 0;
  outline: 0;
  background: unset;
  color: #404040;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
`;

export default VideoSubInfo;
