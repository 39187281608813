import { useMutation } from "@apollo/client";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { CircularProgress } from "@mui/material";
import constants from "asset/constants";
import { PaymentContext } from "asset/context";
import { React, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { v4 as uuidv4 } from "uuid";
import { extractTextFromImage } from "../../../api/askQuestion";
import find_picture from "../../../asset/Img/find_picture.png";
import apiFn from "../../../asset/apiClass";
import store from "../../../asset/store";
import style from "../../../asset/style";
import util from "../../../asset/util";
import { useDeviceWidth } from "../../../hooks";
import { RegisterBulkQuestionsDocument } from "../../../types/graphql/graphql";
import { UiCheckBox } from "../../../uiComponents/UiCheckBox/UiCheckBox";
import { UiInput_title } from "../../../uiComponents/UiInput/UiInput";
import UiTextBtn from "../../../uiComponents/UiTextBtn/UiTextBtn";
import { Frame } from "../../page_index";
import AnswerItem from "./components/AnswerItem";
import QuestionItem from "./components/QuestionItem";
import styles from "./style.module.scss";

const QnaRegi = ({ userInfo, setHiddenUrl }) => {
  let navigate = useNavigate();
  let params = useParams();

  const { academyId } = useContext(PaymentContext);
  const [isOpen, setIsOpen] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [isAnswered, setIsAnswered] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [nameObj, setNameObj] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const deviceWidth = useDeviceWidth();

  var [questionFileList, setQuestionFileList] = useState({});
  var [answerFileList, setAnswerFileList] = useState({});

  const [selectedQuestionName, setSelectedQuestionName] = useState(null);

  const [registerBulkQuestionMutate] = useMutation(RegisterBulkQuestionsDocument, {
    errorPolicy: "all",
  });

  const questionFileChangeHandle = (e) => {
    try {
      let file = (e.target ?? e.dataTransfer).files;
      let array = {};
      // // console.log(file)
      // // console.log(file[0])
      // return;
      let obj = {
        ...questionFileList,
      };
      for (let i = 0; i < file.length; i++) {
        if (!file[i].type.match(/image.*/)) continue;
        // let reader = new FileReader();

        // // console.log(file[i])

        let innerObj = {};
        let key = file[i]["name"].split(".")[0];
        // console.log(i+" => ",file[i])
        // obj[file[i]["name"].split('.')[0]+i]

        innerObj["oriName"] = file[i]["name"].split(".")[0];
        innerObj["fileName"] = file[i]["name"].split(".")[0];

        // obj["oriName"] = file[i]["name"].split('.')[0]
        // obj["fileName"] = file[i]["name"].split('.')[0]

        obj[key] = {
          ...innerObj,
          file: file[i],
        };
        // reader.readAsDataURL(file[i]);
        // reader.onloadend = (e) => {
        //     obj[key]["base64"] = reader.result
        //     // innerObj["base64"] = reader.result
        //
        //     // obj["base64"] = reader.result
        //
        //     // array.push(obj)
        //     // // console.log("fileList = > ",obj)
        //
        setQuestionFileList({
          ...obj,
        });
        //
        //     // setFileList([
        //     //     ...array
        //     // ])
        // }
      }
    } catch (e) {
      console.log("mutil file onChange Error => ", e);
    }
  };
  const answerFileChangeHandle = (e) => {
    try {
      let file = e.target.files;
      let array = {};
      // // console.log(file)
      // // console.log(file[0])
      // return;
      let obj = {
        ...answerFileList,
      };
      for (var i = 0; i < file.length; i++) {
        // // console.log(file[i])

        let innerObj = {};
        let key = file[i]["name"].split(".")[0];
        // console.log(i+" => ",file[i])
        // obj[file[i]["name"].split('.')[0]+i]

        innerObj["oriName"] = file[i]["name"].split(".")[0];
        innerObj["fileName"] = file[i]["name"].split(".")[0];

        // obj["oriName"] = file[i]["name"].split('.')[0]
        // obj["fileName"] = file[i]["name"].split('.')[0]

        obj[key] = {
          ...innerObj,
          file: file[i],
        };
        // reader.readAsDataURL(file[i]);
        // reader.onloadend = (e) => {
        //     obj[key]["base64"] = reader.result
        // innerObj["base64"] = reader.result

        // obj["base64"] = reader.result

        // array.push(obj)
        // // console.log("fileList = > ",obj)

        setAnswerFileList({
          ...obj,
        });

        // setFileList([
        //     ...array
        // ])

        const tempQuestionList = { ...questionFileList };
        const questionFileNameList = Object.keys(questionFileList);
        const answerFileNameList = Object.keys(obj);
        // 질문 사진 갯수와 답변 사진 갯수 중 작은 것을 선택하여 1:1 관게로 연결
        const minLengthToMatch = Math.min(questionFileNameList.length, answerFileNameList.length);

        for (let k = 0; k < minLengthToMatch; k++) {
          tempQuestionList[questionFileNameList[k]].answerNameList = [answerFileNameList[k]];
        }

        // 답변으로 고른 사진이 더 많을 경우, 남은 답변을 마지막 질문에 모두 등록
        if (questionFileNameList.length < answerFileNameList.length) {
          const leftOverAnswerList = answerFileNameList.slice(questionFileNameList.length);
          tempQuestionList[questionFileNameList[minLengthToMatch - 1]].answerNameList = [
            ...tempQuestionList[questionFileNameList[minLengthToMatch - 1]].answerNameList,
            ...leftOverAnswerList,
          ];
        }
        setQuestionFileList(tempQuestionList);
        // }
      }
    } catch (e) {
      console.log("mutil answer file onChange Error => ", e);
    }
  };

  const nameChange = () => {
    try {
      if (!nameObj["head"] && !nameObj["middle"] && !nameObj["end"]) {
      } else {
        // obj["title"] = nameObj["head"] + ( (Number(nameObj["middle"])+i).toString()  ) +nameObj["end"]
        let obj = {
          ...questionFileList,
        };
        Object.keys(obj).map((v, i) => {
          if (!nameObj["head"]) {
            if (!nameObj["middle"]) {
              obj[v]["fileName"] = nameObj["end"];
            } else if (!nameObj["end"]) {
              obj[v]["fileName"] = (Number(nameObj["middle"]) + i).toString();
            } else {
              obj[v]["fileName"] = (Number(nameObj["middle"]) + i).toString() + nameObj["end"];
            }
          } else if (!nameObj["middle"]) {
            if (!nameObj["head"]) {
              obj[v]["fileName"] = nameObj["end"];
            } else if (!nameObj["end"]) {
              obj[v]["fileName"] = nameObj["head"];
            } else {
              obj[v]["fileName"] = nameObj["head"] + nameObj["end"];
            }
          } else if (!nameObj["end"]) {
            if (!nameObj["head"]) {
              obj[v]["fileName"] = (Number(nameObj["middle"]) + i).toString();
            } else if (!nameObj["middle"]) {
              obj[v]["fileName"] = nameObj["head"];
            } else {
              obj[v]["fileName"] = nameObj["head"] + (Number(nameObj["middle"]) + i).toString();
            }
          } else {
            obj[v]["fileName"] =
              nameObj["head"] + (Number(nameObj["middle"]) + i).toString() + nameObj["end"];
          }
        });

        setQuestionFileList({
          ...obj,
        });
      }
    } catch (e) {}
  };

  const processUploadImages = async (data) => {
    try {
      // Register Question
      let filepath = `qna/temp-${userInfo.id}-${new Date().getMilliseconds()}.jpg`;
      const img = await apiFn.uploadFile(filepath, data.file);
      let uploadedData = {
        ...data,
        img,
      };

      const resQna = await store.getTextFromImage(uploadedData);

      // Register Answer
      console.log(`===[debug] data = ${JSON.stringify(data.answers)}`);
      const newQna = resQna.data.result;
      for (const answerFIleName of data.answers) {
        const answer = answerFileList[answerFIleName];

        const name =
          new Date().getTime() + "_" + util.getRandomInt(100, 999) + "_" + answerFIleName;
        const path = `qna/${newQna.qid}/${name}.jpg`;
        await apiFn.uploadFile(path, answer.file);

        const answerData = {
          wtype: 2,
          wid: userInfo.id,
          qid: newQna.qid,
          ctype: 1, // 1 | 10 not source, 11 source
          content: name + ".jpg",
          isAnswered: isAnswered,
        };
        // console.log(`[DEBUG.processUploadImages] answerData = ${JSON.stringify(answerData)}`);
        await store.putAnswerForQuestion(answerData);
      }

      return null;
    } catch (e) {
      console.error(e);
      console.log("fail data", data);
      return data;
    }
  };

  const uploadImageToBucketThenExtractContent = async ({
    questionFileName,
    gicul_rate,
    questionFile,
    answerFileNames = [],
    taskIndex,
  }) => {
    try {
      const tempFilePath = ({ prefix = "", suffix = ".jpg", before = [], after = [] }) =>
        `${prefix}${[...before, uuidv4(), ...after].join("_")}${suffix}`;

      const uploadQuestionImage = async (file, bucketFilePath) => {
        return apiFn
          .uploadFile(bucketFilePath, file)
          .then(() => extractTextFromImage(bucketFilePath))
          .then((result) => ({ ...result, bucketFilePath }));
      };

      const uploadImgBucketTasks = [
        uploadQuestionImage(
          questionFile,
          tempFilePath({ prefix: "temp_", after: [questionFileName] })
        ),
        ...answerFileNames.map(async (fileName) => {
          const bucketTmpPath = tempFilePath({ prefix: "temp_", after: [fileName] });
          const answerFile = answerFileList[fileName].file;
          return apiFn.uploadFile(bucketTmpPath, answerFile).then(() => ({
            // qna/<question.id>/<fileName>
            fileName: `${new Date().getTime()}_${util.getRandomInt(100, 999)}_${fileName}.jpg`,
            filePath: bucketTmpPath,
          }));
        }),
      ];

      const [questionImageUploadResult, ...answerImagesUploadResults] =
        await Promise.all(uploadImgBucketTasks);

      if (questionImageUploadResult && questionImageUploadResult.success) {
        return {
          title: questionFileName,
          giculRate: gicul_rate,
          questionImage: questionImageUploadResult.bucketFilePath,
          answerImages: answerImagesUploadResults,
          content: questionImageUploadResult.content,
          taskIndex,
        };
      } else {
        return false;
      }
    } catch (e) {
      // 질문사진, 답변사진 업로드. 질문사진 텍스트 추출 어느 것 하나라도 에러가 발생하면 실패로 처리
      return false;
    }
  };

  const registerAllQuestions = useCallback(async () => {
    try {
      setIsLoading(true);
      const teacherId = userInfo["id"];
      const bookId = params["id"];
      const checkQnaDate = true;
      const checkReadDate = false;
      const checkMobumDate = false;
      const isMobum = true;
      const questionType = params["type"] == "gi" ? 9 : 0;

      const questions = Object.keys(questionFileList).map((key, index) => {
        const { fileName, rate, answerNameList, file } = questionFileList[key];
        return {
          gicul_rate: rate,
          questionFileName: fileName,
          questionFile: file,
          answerFileNames: answerNameList,
          taskIndex: index,
        };
      });
      // UPLOAD TO BUCKET
      const fileUploadResults = await Promise.all(
        questions.map((question) => uploadImageToBucketThenExtractContent(question))
      );
      // 질문,답변 사진 업로드 + 텍스트 추출에 성공한 질문만 등록 대상
      const toUploadQuestions = fileUploadResults.filter((result) => !!result);

      const mutationResult = await registerBulkQuestionMutate({
        variables: {
          input: {
            teacherId,
            bookId: parseInt(bookId),
            academyId,
            checkQnaDate,
            checkReadDate,
            checkMobumDate,
            isAnswered,
            isMobum,
            questionType,
            questions: toUploadQuestions,
            isDev: constants.isDev,
          },
        },
      });

      const succesTasks = mutationResult.data.registerBulkQuestions.successTasks;
      // console.log("succesTasks", JSON.stringify(succesTasks));
      const failureTasks = Object.keys(questionFileList)
        .map((key, idx) => (!succesTasks.includes(idx) ? idx : null))
        .filter((idx) => idx !== null);
      // console.log("failureTasks", JSON.stringify(failureTasks));

      const retryTasks = failureTasks.reduce((acc, index) => {
        const key = Object.keys(questionFileList)[index];
        const value = questionFileList[key];
        return {
          ...acc,
          [key]: value,
        };
      }, {});

      let text =
        `${succesTasks.length}개의 모범 문항이 등록되었어요.` +
        (failureTasks.length > 0
          ? `(${failureTasks.length}개의 문항이 등록되지 않았어요. 부적합한 문항 사진이 사용되지 않았는지 확인해주세요.)`
          : "");
      alert(text);

      const stack = store.get("stack");
      if (failureTasks.length <= 0 && stack.length > 0) {
        navigate(-1);
      } else if (failureTasks.length <= 0) {
        navigate("/");
      } else {
        setQuestionFileList(retryTasks);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  }, [userInfo, params, academyId, questionFileList]);

  // 문제 질문 등록
  const apiPro_set = async () => {
    try {
      setIsLoading(true);
      let array = [];
      let qnaData = {
        tid: userInfo["id"],
        bid: params["id"],
        qna_date: "y",
        // ans_date : "y",
        mobum_date: "y",
        new: "y",
        mobum: "y",
      };

      if (params["type"] == "gi") {
        qnaData["qtype"] = 9;
      }

      // console.log("fileList :" ,fileList)
      Object.keys(questionFileList).map((v, i) => {
        // console.log("FL : ",v)
        let obj = {
          ...qnaData,
          title: questionFileList[v]["fileName"],
          // base64 : fileList[v]["base64"].replace(/^data:image\/png;base64,/, "").replace(/^data:image\/jpeg;base64,/, ""),
          // oriFile: questionFileList[v]["base64"],
          gicul_rate: questionFileList[v]["rate"],
          answers: questionFileList[v].answerNameList ?? [],
          file: questionFileList[v].file,
        };
        array.push(obj);
      });
      console.log("array", array);

      let promiseArray = array.map((v) => processUploadImages(v));
      const failedData = (await Promise.all([...promiseArray])).filter((result) => result);

      let text = `${promiseArray.length}개의 모범 문항이 등록되었어요.`;
      if (failedData.length > 0) {
        text = `${promiseArray.length - failedData.length}개의 모범 문항이 등록되었어요.`;
        text =
          text +
          `(${failedData.length}개의 문항이 등록되지 않았어요. 부적합한 문항 사진이 사용되지 않았는지 확인해주세요.)`;
      }
      alert(text);

      /**
       * By Gordon Ahn....
       */
      const stack = store.get("stack");
      if (failedData.length <= 0 && stack.length > 0) {
        navigate(-1);
      } else if (failedData.length <= 0) {
        navigate("/");
      } else {
        setQuestionFileList(
          failedData.map((data) => ({
            ...data,
            fileName: data.title,
            answerNameList: data.answers,
          }))
        );
      }

      setIsLoading(false);
    } catch (e) {
      // console.log("QNA SET Error => ",e);
      setIsLoading(false);
      // alert("문제 사진으로 적합하지 않은 이미지에요. 사진을 다시 한번 확인해주세요.")
      // // console.log("hiddenUrl = > ",hiddenUrl);
      // navigate(hiddenUrl)
      // if(e["code"] == "NO_QNA_STRING"){
      //     alert(e["msg"])

      // }
    }
  };

  useEffect(() => {
    // console.log("parmas => ",params)
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
  }, [params]);

  useEffect(() => {}, []);

  return (
    <Frame
      userInfo={userInfo}
      type="2"
      isBack={true}
      isSearch={false}
      title={
        <div
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "28px",
            textAlign: "center",
            letterSpacing: "2px",
          }}
        >
          <div
            style={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            문항 등록
          </div>
        </div>
      }
    >
      <>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              width: "100%",
              // height:"100vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "50px 0px",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  width: "75vw",
                }}
                src={find_picture}
              />
              <CircularProgress />
            </div>
            <UiTextBtn
              btnStyle={{
                color: style.common.fontColor_3,
                fontSize: "14px",
                padding: "20px 0px",
              }}
              text={"작업 취소하기"}
              fnClick={() => {
                setIsLoading(false);
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              width: "100%",
              height: "100%",
              // height : window.screen.height,
              // height : window.innerHeight,
              // width : window.screen.width,
            }}
          >
            <div
              style={{
                display: "flex",
                // flex:1,
                flexDirection: "column",
                alignItems: "center",
                padding: "12px 0px",
                color: "#A5A5A5",
                fontWeight: 400,
                fontSize: "14px",
              }}
            >
              <div>질문 사진과 답변 사진을 차례대로 선택해주세요.</div>
              <div>모범 풀이의 이름을 일괄 수정하길 원하시면</div>
              <div>아래 화살표를 눌러보세요!</div>
            </div>
            {isOpen && (
              <div
                style={{
                  padding: "0px 10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    // width:"100%",

                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      padding: "10px 20px",
                      flex: 1,
                      width: "100%",
                      flexDirection: "column",
                      backgroundColor: "#FFFBF5",
                      borderRadius: "10px",
                      alignItems: "center",
                    }}
                  >
                    <UiInput_title
                      title="머리말"
                      type="basic"
                      inputClass="input_t"
                      c_style={{
                        width: "100%",
                      }}
                      t_style={{
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      basic_container_style={{
                        backgroundColor: "transparent",
                      }}
                      fnChange={(e) => {
                        setNameObj({
                          ...nameObj,
                          head: e.target.value,
                        });
                      }}
                      placeholder="이름의 맨 앞에 공통으로 들어갈 단어를 입력해주세요."
                    />
                    <UiInput_title
                      title="시작숫자"
                      type="basic"
                      inputClass="input_t"
                      fnChange={(e) => {
                        setNameObj({
                          ...nameObj,
                          middle: e.target.value,
                        });
                      }}
                      t_style={{
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      c_style={{
                        width: "100%",
                      }}
                      basic_container_style={{
                        backgroundColor: "transparent",
                      }}
                      placeholder="시작 숫자를 입력해주세요."
                    />
                    <UiInput_title
                      title="끝말"
                      type="basic"
                      inputClass="input_t"
                      fnChange={(e) => {
                        setNameObj({
                          ...nameObj,
                          end: e.target.value,
                        });
                      }}
                      t_style={{
                        fontSize: "14px",
                        fontWeight: 700,
                      }}
                      c_style={{
                        width: "100%",
                      }}
                      basic_container_style={{
                        backgroundColor: "transparent",
                      }}
                      placeholder="이름의 맨 뒤에 공통으로 들어갈 단어를 입력해주세요."
                    />
                    {isOpen && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <UiTextBtn
                          text={"적용"}
                          isUnderline={false}
                          btnStyle={{
                            color: "#444",
                            fontSize: "14px",
                          }}
                          fnClick={() => {
                            nameChange();
                          }}
                        />
                        <UiTextBtn
                          text={"접기"}
                          isUnderline={false}
                          btnStyle={{
                            marginLeft: "5px",
                            color: "#B3B3B3",
                            fontSize: "14px",
                          }}
                          fnClick={() => {
                            // nameChange()
                            setIsOpen(false);
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {!isOpen ? (
              <div
                style={{
                  display: "flex",
                  // flex:1,
                  flexDirection: "column",
                  alignItems: "center",
                  // borderBottom : "1px solid",
                  // borderBottomColor : style.common.borderColor,
                  padding: "10px 10px 20px 10px",
                  paddingBottom: isOpen ? "30px" : "10px",
                  // backgroundColor:'red',
                  height: "25px",
                }}
              >
                {isOpen ? (
                  <KeyboardArrowUp
                    onClick={() => {
                      setIsOpen(false);
                    }}
                  />
                ) : (
                  <KeyboardArrowDown
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  />
                )}
              </div>
            ) : (
              <div
                style={{
                  padding: "15px 10px 20px 10px",
                }}
              />
            )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <UiCheckBox
                defaultChecked={isAnswered}
                className="t"
                value="isAnswered"
                fnClick={() => {
                  setIsAnswered((prev) => !prev);
                }}
              />
              <div style={{ margin: "0 10px" }}>답변 완료 처리</div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginBottom: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  // flex:1,
                  alignItems: "center",
                  width: "280px",
                  justifyContent: "space-around",
                }}
              >
                {(Object.keys(questionFileList).length === 0 ||
                  Object.keys(answerFileList).length === 0) && (
                  <div
                    style={{
                      padding: "0 10px",
                      height: "32px",
                      borderRadius: "15px",
                      backgroundColor:
                        Object.keys(questionFileList).length > 0 ? "rgb(72, 127, 186)" : "#F7B865",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontWeight: 700,
                      fontSize: "14px",
                    }}
                  >
                    <input
                      type="file"
                      id="qnaRegi"
                      multiple={true}
                      accept="image/*"
                      style={{
                        display: "none",
                      }}
                      onChange={(e) => {
                        if (Object.keys(questionFileList).length === 0) questionFileChangeHandle(e);
                        else answerFileChangeHandle(e);
                        e.target.value = null;
                      }}
                    />
                    <label
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      htmlFor="qnaRegi"
                    >
                      {Object.keys(questionFileList).length > 0 ? "답변" : "질문"}&nbsp;일괄 추가
                    </label>
                  </div>
                )}
                {Object.keys(questionFileList).length > 0 && (
                  <div
                    style={{
                      width: "75px",
                      height: "32px",
                      borderRadius: "15px",
                      backgroundColor: "#5EA3C6",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontWeight: 700,
                      fontSize: "14px",
                    }}
                    onClick={registerAllQuestions}
                  >
                    등록
                  </div>
                )}
                {(Object.keys(questionFileList).length > 0 ||
                  Object.keys(answerFileList).length > 0) && (
                  <div
                    style={{
                      width: "75px",
                      height: "32px",
                      borderRadius: "15px",
                      backgroundColor: "rgb(240, 146, 148)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontWeight: 700,
                      fontSize: "14px",
                    }}
                    onClick={() => {
                      setQuestionFileList({});
                      setAnswerFileList({});
                    }}
                  >
                    초기화
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                padding: "0px 10px 10px 10px",
                display: "flex",
              }}
            >
              {Object.keys(questionFileList).length <= 0 && deviceWidth > 500 ? (
                <div
                  style={{
                    borderColor: isDragging ? style.common.s_color : "#000",
                  }}
                  className={styles.DropZone}
                  onDrop={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    delete e.target;
                    questionFileChangeHandle(e);
                    setIsDragging(false);
                  }}
                  onDragEnd={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDragOver={(e) => {
                    if (e.dataTransfer.files) {
                      setIsDragging(true);
                    }
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  문제 이미지를 여기에 끌어 놓기
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      padding: Object.keys(questionFileList).length == 0 ? "0px" : "10px 0px",
                      // paddingBottom:"10px",
                      borderRadius: "10px",
                      // width : listStat == "gridview" ? "100%" : "auto",
                      backgroundColor: "#FEFCEA",
                      overflowY: "auto",
                      maxWidth: "50%",
                      height: 500,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        // flexGrow : 4,
                        // flex:1,
                        justifyContent: "center",
                      }}
                    >
                      {Object.keys(questionFileList).map((v, i) => (
                        <QuestionItem
                          key={v}
                          objKey={v}
                          data={questionFileList[v]}
                          setFileList={setQuestionFileList}
                          fileList={questionFileList}
                          selectedQuestionName={selectedQuestionName}
                          onSelect={(qName) => setSelectedQuestionName(qName)}
                          onDrop={(e) => {
                            console.log(e);
                          }}
                        />
                      ))}
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      padding: Object.keys(questionFileList).length === 0 ? "0px" : "10px 0px",
                      // paddingBottom:"10px",
                      borderRadius: "10px",
                      // width : listStat == "gridview" ? "100%" : "auto",
                      backgroundColor: "rgb(172, 208, 203)",
                      overflowY: "auto",
                      maxWidth: "50%",
                      height: 500,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "100%",
                        // flexGrow : 4,
                        // flex:1,
                        justifyContent: "center",
                      }}
                    >
                      {questionFileList[selectedQuestionName]?.answerNameList?.map((v) => {
                        return (
                          <AnswerItem
                            deleteAnswerFromQuestionList={(key) =>
                              setQuestionFileList((prev) => {
                                const temp = { ...prev };
                                temp[selectedQuestionName].answerNameList = temp[
                                  selectedQuestionName
                                ].answerNameList.filter((answer) => answer !== key);
                                return temp;
                              })
                            }
                            onDragStart={(e) => {
                              const findAnswers = document
                                .elementsFromPoint(
                                  e.changedTouches?.[0].clientX ?? e.target.clientX,
                                  e.changedTouches?.[0].clientY ?? e.clientY
                                )
                                .filter((el) => el.id.startsWith("A:"));

                              const moving = findAnswers[0];
                              const { left, top } = moving.getBoundingClientRect();
                              moving.style.position = "absolute";
                              moving.style.left =
                                (e.changedTouches?.[0].clientX ?? e.target.clientX) -
                                50 -
                                left +
                                "px";
                              moving.style.top =
                                (e.changedTouches?.[0].clientY ?? e.clientY) - 50 + "px";
                            }}
                            onDrag={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              // @TODO : make border when it hovers Question, and disappear border when it left
                              // const foundQuestions = document.elementsFromPoint(
                              //     e.changedTouches[0].clientX,
                              //     e.changedTouches[0].clientY
                              // ).filter(el => el.id.startsWith('Q:'))

                              // if(foundQuestions.length > 0) {
                              //     foundQuestions[0].style.border = '3px solid #000'
                              // }

                              const foundAnswers = document
                                .elementsFromPoint(
                                  e.changedTouches?.[0].clientX ?? e.clientX,
                                  e.changedTouches?.[0].clientY ?? e.clientY
                                )
                                .filter((el) => el.id.startsWith("A:"));

                              const moving = foundAnswers[0];

                              if (moving) {
                                const { width } = moving.getBoundingClientRect();
                                const moveX =
                                  (e.changedTouches?.[0].clientX ?? e.clientX) - 50 - width / 2;
                                const moveY = (e.changedTouches?.[0].clientY ?? e.clientY) - 50;
                                moving.style.left = moveX + "px";
                                moving.style.top = moveY + "px";
                                moving.style.zIndex = 1000;
                              }
                            }}
                            onDragEnd={(e) => {
                              const findQuestions = document
                                .elementsFromPoint(
                                  e.changedTouches?.[0].clientX ?? e.clientX,
                                  e.changedTouches?.[0].clientY ?? e.clientY
                                )
                                .filter((el) => el.id.startsWith("Q:"));

                              const foundAnswers = document
                                .elementsFromPoint(
                                  e.changedTouches?.[0].clientX ??
                                    e.target.getBoundingClientRect().left,
                                  e.changedTouches?.[0].clientY ??
                                    e.target.getBoundingClientRect().top
                                )
                                .filter((el) => el.id.startsWith("A:"));

                              const moving = foundAnswers[0];
                              if (findQuestions.length === 1) {
                                const targetID = findQuestions[0].id.split(":")[1]; // Q:[targetID]
                                const answerID = moving.id.split(":")[1];

                                setQuestionFileList((prev) => {
                                  const temp = { ...prev };
                                  if (!temp[targetID].answerNameList)
                                    temp[targetID].answerNameList = [];
                                  temp[targetID].answerNameList.push(answerID);

                                  temp[selectedQuestionName].answerNameList = temp[
                                    selectedQuestionName
                                  ].answerNameList.filter((answerKey) => answerKey !== answerID);

                                  const questionKeyList = Object.keys(prev);
                                  const questionIndex = questionKeyList.findIndex(
                                    (key) => key === selectedQuestionName
                                  );
                                  const targetIndex = questionKeyList.findIndex(
                                    (key) => key === targetID
                                  );
                                  if (questionIndex > targetIndex) {
                                    let target = selectedQuestionName;
                                    for (const qKey of questionKeyList.slice(questionIndex + 1)) {
                                      const nextAnswerName = temp[qKey].answerNameList[0];
                                      if (nextAnswerName) {
                                        temp[qKey].answerNameList =
                                          temp[qKey].answerNameList.slice(1);
                                        temp[target].answerNameList.push(nextAnswerName);
                                      }
                                      target = qKey;
                                    }
                                  }

                                  return temp;
                                });
                              }
                              // Take it back to the place
                              if (moving) {
                                moving.style.position = "";
                                moving.style.top = "";
                                moving.style.left = "";
                                moving.style.zIndex = "";
                              }
                            }}
                            key={v}
                            objKey={v}
                            data={answerFileList[v]}
                            setFileList={setAnswerFileList}
                            fileList={answerFileList}
                            selectedQuestionName={selectedQuestionName}
                          />
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </>
    </Frame>
  );
};

export default QnaRegi;
