import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import {
  Image,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  Link,
  Videocam,
} from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Slider from "react-slick";
import noBook from "../../asset/Img/book1.png";
import gridview from "../../asset/Img/gridview.png";
import imageview from "../../asset/Img/imageview.png";
import listview from "../../asset/Img/listview.png";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import style from "../../asset/style";
import Basic from "../../common/frame/Basic";
import { UiCheckBox } from "../../uiComponents/UiCheckBox/UiCheckBox";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";
import UiQna from "../QNA/UiQna";
import UiQna_grid from "../QNA/UiQna_grid";
import UiQna_image from "../QNA/UiQna_image";
import "./Search_result.css";
import styles from "./postSearch.module.scss";
import { getUser } from "../../asset/storage";
import { questionSearchOption, useQuestionSearch } from "../../api/questionSearch";
import QuestionGridItem from "./components/QuestionGridItem";
import QuestionImageItem from "./components/QuestionImageItem";
import QuestionListItem from "./components/QuestionListItem";
import { useDeleteQuestion } from "../../api/question";

export const iconTypeMapping = {
  1: <Image style={{ color: "#487fba", fontSize: "18px", marginRight: "2px" }} />,
  2: <Videocam style={{ color: "#487fba", fontSize: "18px", marginRight: "3px" }} />,
  3: <Videocam style={{ color: "#487fba", fontSize: "18px", marginRight: "3px" }} />,
  4: <Link style={{ color: "#487fba", fontSize: "18px", marginRight: "3px" }} />,
  8: <Link style={{ color: "#487fba", fontSize: "18px", marginRight: "3px" }} />,
};
const Post_Search = ({
  setOri_data,
  ori_data,
  isSR_detail,
  setIsSR_detail,
  qnaData,
  setHiddenUrl,
  oriQnaRoom,
  setScrolllKey,
  solutionList,
  setSolutionList,
}) => {
  let params = useParams();
  let navigate = useNavigate();
  const userInfo = getUser();

  let sort = [
    { name: "이름순", value: "name" },
    { name: "개설순", value: "reg_date" },
    { name: "수강인원순", value: "n03" },
  ];

  let sort_count = [
    { name: "5", value: 5 },
    { name: "10", value: 10 },
    { name: "20", value: 20 },
    { name: "50", value: 50 },
  ];
  let sort_qna = [
    { name: "모범 문항", value: "mobum" },
    { name: "완료 문항", value: "qna" },
    { name: "질문 문항", value: "live" },
    { name: "전체 문항", value: "all" },
  ];

  var [sortingCount, setSortingCount] = useState(5);
  var [sortingQna, setSortingQna] = useState("모범 문항");

  var [isSort, setIsSort] = useState(false);
  var [isSort_qna, setIsSort_qna] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [isLoading_sort, setIsLoading_sort] = useState(false);

  var [isNoResult, setIsNoResult] = useState(false);

  var [sorting, setSorting] = useState("");

  var [isMy, setIsMy] = useState(
    window.localStorage.getItem("search_result_my")
      ? JSON.parse(window.localStorage.getItem("search_result_my"))
      : false
  );

  var [listStat, setListStat] = useState("mobum");

  var [listForm, setListForm] = useState("listview");
  const [currentSlide, setCurrentSlider] = useState(0);
  const sliderRef = useRef();
  const [isSearchDataLoading, setIsSearchDataLoading] = useState(false);

  const [isAnswer, setIsAnswer] = useState(false);
  const [bid, setBid] = useState(window.localStorage.getItem("talk_bookId") ?? null);

  const { data: postQuestionResponse } = useQuestionSearch({
    userId: userInfo.id,
    userType: userInfo.type,
    option: questionSearchOption[listStat],
    bookTypes: [currentSlide],
    limit: Number(sortingCount) ?? 5,
    searchData: params["qid"].includes("&") ? params["qid"].split("&")[0] : params["qid"],
    searchType: "question",
    myQuestion: isMy,
  });

  const getAnswerIcons = (questionData) => {
    if ([1, 2].includes(questionData?.HIQDB_book_links?.[0]?.bid_HIQDB_book?.book_type))
      return [<></>];
    if (questionData?.HIQDB_online_qna_msgs?.length === 0) return [<div>답변 없음</div>];

    const icons = new Set(
      questionData?.HIQDB_online_qna_msgs?.map((msg) => iconTypeMapping[msg.ctype]).filter(Boolean)
    );

    return [...icons];
  };

  useEffect(() => {
    apiPro_read(undefined, sorting, isMy, isAnswer);
  }, [currentSlide]);

  const titleContent = useMemo(() => {
    let text = "문제검색결과";
    if (currentSlide === 1) text = "PDF 검색결과";
    else if (currentSlide === 2) text = "내 필기 검색결과";
    else if (currentSlide === 3) text = "시중 교재 검색 결과";
    return text;
  }, [currentSlide]);
  const apiPro_read = async (amount, status, my, isAnswer = false) => {
    try {
      setIsSearchDataLoading(true);
      let state = {};
      let _status = "";
      state["status"] = "mobum";
      console.log("AR : ", status);
      if (status) {
        if (status == "all") {
          // state["status"] = ""
          _status = "";
        } else {
          // state["status"] = status
          _status = status;
        }
      } else {
        if (window.localStorage.getItem("s_result_sort")) {
          if (JSON.parse(window.localStorage.getItem("s_result_sort"))["value"] == "all") {
            _status = "";
          } else {
            _status = JSON.parse(window.localStorage.getItem("s_result_sort"))["value"];
          }
        } else {
          _status = listStat;
        }
      }
      state["status"] = _status;

      if (my) {
        state["isMy"] = "Y";
      } else {
        // state["isMy"] = "N"
        if (window.localStorage.getItem("search_result_my")) {
          if (JSON.parse(window.localStorage.getItem("search_result_my"))) {
            state["isMy"] = "Y";
          }
        }
      }

      if (window.localStorage.getItem("isAnswer")) {
        state.isAnswer = true;
      } else {
        state.book_type = currentSlide;
      }

      let _amount = 0;
      if (amount) {
        _amount = Number(amount) + 1;
      } else if (window.localStorage.getItem("s_result_amount")) {
        _amount = Number(JSON.parse(window.localStorage.getItem("s_result_amount"))) + 1;
      } else {
        _amount = 5 + 1;
      }

      if (_status == "mobum") {
        _amount = _amount - 1;
      }

      let isOk = await apiFn.qnaList({
        state: {
          ...state,
          id: userInfo["id"], // params["qid"].includes("&") ? params["qid"].split("&")[1] : userInfo["id"],
          tid: userInfo["id"],
          isExcept: "Y",
          page: 1,
          amount: amount
            ? amount
            : window.localStorage.getItem("s_result_amount")
              ? JSON.parse(window.localStorage.getItem("s_result_amount"))
              : 5,
          sort: "reg_date desc", // img 처리일 때는 무시됨.
          s_qid: params["qid"].includes("&") ? params["qid"].split("&")[0] : params["qid"],
          bid,
        },
      });
      let s_list = [];
      isOk["list"].map((v, i) => {
        // console.log("original : ",v["original"])
        s_list.push(v);
        if (v["id"] != oriQnaRoom) {
        }
      });
      console.log("s_list => ", s_list);
      setSolutionList([...s_list]);

      // setSolutionList([
      //     ...isOk["list"]
      // ])
    } catch (e) {
      console.log("Sesult Read ERROR : ", e);
    } finally {
      setIsLoading_sort(false);
      setIsLoading(false);
      setIsSearchDataLoading(false);
    }
  };

  const apiPro_read_ori = async () => {
    if (solutionList) {
      setOri_data(qnaData);
      setIsLoading(false);
      return;
    }
    try {
      let isOk = await apiFn.qnaList({
        state: {
          id: params["qid"].includes("&") ? params["qid"].split("&")[1] : userInfo["id"],
          page: 1,
          amount: 999,
          tid: userInfo["id"],
          isExcept: "Y",
          sort: "reg_date desc",
          s_qid: params["qid"].includes("&") ? params["qid"].split("&")[0] : params["qid"],
        },
      });
      // console.log("isOk === > ", isOk)
      if (isOk["list"].length > 0) {
        setOri_data({
          ...isOk["list"][0],
        });
      } else {
        console.log("$$$");
        setIsNoResult(true);
        setOri_data({
          id: 0,
        });
      }
    } catch (e) {
      console.log("## ", e);
    } finally {
      setIsLoading(false);
    }
  };

  const apiPro_del = async (data) => {
    try {
      console.log("del data : ", data);
      await useDeleteQuestion(data.id);
      await apiPro_read();
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (window.localStorage.getItem("search_result_my")) {
      setIsMy(JSON.parse(window.localStorage.getItem("search_result_my")));
    }
    if (window.localStorage.getItem("s_result_amount")) {
      setSortingCount(Number(JSON.parse(window.localStorage.getItem("s_result_amount"))));
    }
    if (window.localStorage.getItem("s_result_sort")) {
      setListStat(JSON.parse(window.localStorage.getItem("s_result_sort"))["value"]);
      setSortingQna(JSON.parse(window.localStorage.getItem("s_result_sort"))["name"]);
      console.log(JSON.parse(window.localStorage.getItem("s_result_sort")));
    }
    if (window.localStorage.getItem("explain_section")) {
      setListForm(window.localStorage.getItem("explain_section"));
    }
  }, [params]);

  useEffect(() => {
    // console.log("@@@@#@#@ #@#@#@#@#@#@#")
    if (isSR_detail) {
      setIsLoading(false);
      setIsSR_detail(false);
    } else if (solutionList?.length <= 0) {
      apiPro_read();
    }

    apiPro_read_ori();

    if (window.localStorage.getItem("s_result_sort")) {
      console.log("$$ ", window.localStorage.getItem("s_result_sort"));
    }
  }, []);

  useEffect(() => {
    apiPro_read(undefined, sorting, isMy, isAnswer);
  }, [bid]);

  useEffect(
    () => () => {
      window.localStorage.removeItem("isAnswer");
      window.localStorage.removeItem("talk_bookId");
      window.localStorage.removeItem("postImg");
    },
    []
  );
  useEffect(() => {
    console.log("qnaData", qnaData);
  }, [qnaData]);
  return (
    <>
      {!isLoading && (
        <Basic
          isPadding={false}
          userInfo={userInfo}
          title={titleContent}
          navi={`/message/${params["who"]}/home`}
          containerStyle={{ overflow: "hidden", maxHeight: "100vh" }}
          pastSearchQna={true}
        >
          {isNoResult ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              검색 할 수 있는 데이터가 없습니다.
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flex: 1,
                paddingBottom: "10px",
                flexDirection: "column",
                marginTop: "15px",
                height: "calc(100% - 50px)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  maxHeight: "30%",
                  justifyContent: "center",
                  overflowY: "auto",
                }}
              >
                <div
                  className="solCrop__drag-handle"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "85vw",
                    height: "fit-content",
                    position: "relative",
                  }}
                >
                  {
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                        border: "1px solid",
                        borderColor: style.common.borderColor4,
                        borderRadius: "5px",
                      }}
                      onError={(e) => {
                        if (ori_data) {
                          e.currentTarget.src = noBook;
                        }
                      }}
                      src={`${constants.s3_url}/qna/${window.localStorage.getItem("postImg") ? window.localStorage.getItem("postImg") : qnaData["img"]}.jpg`}
                    />
                  }
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  marginTop: "10px",
                  // backgroundColor : style.common.borderColor2,
                  height: "60%",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    padding: "10px 10px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        {
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                fontSize: "14px",
                                fontWeight: 400,
                                alignItems: "center",
                              }}
                            >
                              {/* {qnaData.length > 0 && `총 ${qnaData[0]["tcnt"]} 문항`} */}
                              {`${sortingCount ? sortingCount : "5"}개씩`}
                              {isSort ? (
                                <ArrowDropUp
                                  style={{
                                    fontSize: "40px",
                                    marginBottom: "3px",
                                  }}
                                  onClick={() => {
                                    setIsSort(false);
                                  }}
                                />
                              ) : (
                                <ArrowDropDown
                                  style={{
                                    fontSize: "40px",
                                    marginBottom: "3px",
                                  }}
                                  onClick={() => {
                                    setIsSort(true);
                                  }}
                                />
                              )}
                            </div>
                            {isSort ? (
                              <div
                                style={{
                                  position: "absolute",
                                  left: 0,
                                  // top:10,
                                  borderRadius: "20px",
                                  border: "1px solid #F6F6F6",
                                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                  padding: "3px 0px",
                                  backgroundColor: "white",
                                  zIndex: 10,
                                }}
                              >
                                {sort_count.map((v, i) => (
                                  <div
                                    style={{
                                      padding: "15px 0px",
                                      width: "120px",
                                      backgroundColor: "white",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      fontWeight: 400,
                                      fontSize: "14px",
                                      color: "#3D3A39",
                                      borderRadius: "20px",
                                    }}
                                    onClick={(e) => {
                                      // console.log(v)
                                      setIsLoading_sort(true);
                                      setSortingCount(v["value"]);
                                      setIsSort(false);
                                      window.localStorage.setItem("s_result_amount", v["value"]);
                                      apiPro_read(v["value"], sorting, isMy, isAnswer);
                                    }}
                                  >
                                    {v["name"]}
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        }

                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              fontSize: "14px",
                              fontWeight: 400,
                              alignItems: "center",
                            }}
                          >
                            {`${sortingQna ? sortingQna : "모범문항"}`}
                            {isSort_qna ? (
                              <ArrowDropUp
                                style={{
                                  fontSize: "40px",
                                  marginBottom: "3px",
                                }}
                                onClick={() => {
                                  setIsSort_qna(false);
                                }}
                              />
                            ) : (
                              <ArrowDropDown
                                style={{
                                  fontSize: "40px",
                                  marginBottom: "3px",
                                }}
                                onClick={() => {
                                  setIsSort_qna(true);
                                }}
                              />
                            )}
                          </div>
                          {isSort_qna ? (
                            <div
                              style={{
                                position: "absolute",
                                left: 0,
                                // top:10,
                                borderRadius: "20px",
                                border: "1px solid #F6F6F6",
                                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                padding: "3px 0px",
                                backgroundColor: "white",
                                zIndex: 10,
                              }}
                            >
                              {sort_qna.map((v, i) => (
                                <div
                                  style={{
                                    padding: "15px 0px",
                                    width: "120px",
                                    backgroundColor: "white",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    color: "#3D3A39",
                                    borderRadius: "20px",
                                  }}
                                  onClick={(e) => {
                                    setIsLoading_sort(true);
                                    window.localStorage.setItem("s_result_sort", JSON.stringify(v));
                                    setSortingQna(v["name"]);
                                    setIsSort_qna(false);
                                    apiPro_read(undefined, v.value, isMy, isAnswer);
                                  }}
                                >
                                  {v["name"]}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "15px",
                        }}
                      >
                        {listForm === "listview" ? (
                          <img
                            src={listview}
                            style={{
                              width: "22px",
                              height: "22px",
                            }}
                            onClick={() => {
                              setListForm("gridview");
                              window.localStorage.setItem("explain_section", "gridview");
                            }}
                          />
                        ) : listForm === "gridview" ? (
                          <img
                            src={gridview}
                            style={{
                              width: "22px",
                              height: "22px",
                            }}
                            onClick={() => {
                              setListForm("imageview");
                              window.localStorage.setItem("explain_section", "imageview");
                            }}
                          />
                        ) : (
                          // listStat == "imageview" &&
                          <img
                            src={imageview}
                            style={{
                              width: "22px",
                              height: "22px",
                            }}
                            onClick={() => {
                              setListForm("listview");
                              window.localStorage.setItem("explain_section", "listview");
                            }}
                          />
                        )}
                      </div>
                      {!window.localStorage.getItem("isAnswer") && (
                        <>
                          <UiCheckBox
                            value={"isImportant"}
                            name="iI"
                            className={params["who"] == "teacher" ? "t" : "s"}
                            checked={isMy}
                            fnCheck={(e) => {
                              window.localStorage.setItem(
                                "search_result_my",
                                JSON.stringify(!isMy)
                              );
                              apiPro_read(undefined, sorting, !isMy, isAnswer);
                              setIsMy((prev) => !prev);
                            }}
                          />
                          <div
                            style={{
                              marginLeft: 3,
                            }}
                          >
                            내 {currentSlide === 0 ? "문항" : "PDF"}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    // backgroundColor:"white",
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px 5px",
                    // overflow:"sco"
                    overflowY: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                      >
                        검색결과
                      </span>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: 700,
                          marginLeft: "6px",
                        }}
                      >
                        {`총 ${postQuestionResponse?.length ?? 0} 문항`}
                      </span>
                    </div>
                    {!window.localStorage.getItem("isAnswer") && (
                      <div className={styles.buttonSection}>
                        <button
                          className={styles.btn}
                          onClick={() => {
                            sliderRef.current.slickGoTo(currentSlide - 1);
                          }}
                        >
                          <KeyboardDoubleArrowLeft />
                          {currentSlide === 0
                            ? "시중 교재 검색 결과 보기"
                            : currentSlide === 1
                              ? "문제 검색 보기"
                              : currentSlide === 2
                                ? "PDF 검색 결과 보기"
                                : "내 필기 검색 보기"}
                        </button>
                        <button
                          className={styles.btn}
                          onClick={() => {
                            sliderRef.current.slickGoTo(currentSlide + 1);
                          }}
                        >
                          {currentSlide === 0
                            ? "PDF 검색 결과 보기"
                            : currentSlide === 1
                              ? "내 필기 검색 보기"
                              : currentSlide === 2
                                ? "시중 교재 검색 결과 보기"
                                : "문제 검색 보기"}
                          <KeyboardDoubleArrowRight />
                        </button>
                      </div>
                    )}
                  </div>
                  {window.localStorage.getItem("isAnswer") === "true" ? (
                    <>
                      {solutionList?.length > 0 ? (
                        listStat == "gridview" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                            }}
                          >
                            {solutionList?.map((v, i) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexGrow: 1,
                                  maxWidth: "200px",
                                  justifyContent: "center",
                                }}
                              >
                                <UiQna_grid
                                  type={v.type}
                                  isDot={true}
                                  index={i}
                                  data={v}
                                  isTeacher={params["who"] == "teacher" ? true : false}
                                  fnClick={() => {
                                    setIsSR_detail(true);
                                    window.localStorage.setItem("isImgSearch", true);
                                    navigate(
                                      `/pdfView/${v?.bid}?questionId=${v?.id}&prevStep=answer&type=answer&prevQuestion=${params.qid}`,
                                      { replace: true }
                                    );
                                    // navigate(`/talk/${params["who"]}/searchresult/${d["id"]}/${d["sid"]}/${d["tid"]}`)
                                  }}
                                  apiPro={(id) => {
                                    apiPro_read(undefined, undefined, sorting.value, isMy);
                                  }}
                                  apiPro_del={(d) => {
                                    apiPro_del(d);
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          solutionList?.map((v, i) =>
                            listStat === "listview" ? (
                              // <UiExplain
                              <UiQna
                                statInfo={{ type: v.type }}
                                index={i}
                                isSearch={true}
                                isTeacher={params["who"] === "teacher"}
                                isDot={true}
                                isMsgSection={false}
                                setScrolllKey={setScrolllKey}
                                scrollKey={scrollKey}
                                data={v}
                                fnLongClick={() => {}}
                                fnClick={(d) => {
                                  setIsSR_detail(true);
                                  setScrolllKey(d["id"]);
                                  window.localStorage.setItem("isImgSearch", true);
                                  navigate(
                                    `/pdfView/${v?.bid}?questionId=${v?.id}&prevStep=answer&type=answer&prevQuestion=${params.qid}`,
                                    { replace: true }
                                  );
                                }}
                                apiPro={(id) => {
                                  apiPro_read(undefined, undefined, sorting.value, isMy);
                                }}
                                apiPro_del={(d) => {
                                  apiPro_del(d);
                                }}
                              />
                            ) : (
                              // solutionList.map((v,i)=>
                              <UiQna_image
                                statInfo={{ type: v.type }}
                                index={i}
                                isSearch={true}
                                isDot={true}
                                data={v}
                                isTeacher={params["who"] === "teacher"}
                                fnClick={(d) => {
                                  setIsSR_detail(true);
                                  window.localStorage.setItem("isImgSearch", true);
                                  navigate(
                                    `/pdfView/${v?.bid}?questionId=${v?.id}&prevStep=answer&type=answer&prevQuestion=${params.qid}`,
                                    { replace: true }
                                  );
                                  // navigate("/talk/"+params["who"]+"/"+"post"+"/"+d["id"]+"/"+d["sid"]+"/"+d["tid"])
                                }}
                                apiPro={(id) => {
                                  apiPro_read(undefined, undefined, sorting.value, isMy);
                                }}
                                apiPro_del={(d) => {
                                  apiPro_del(d);
                                }}
                              />
                            )
                          )
                        )
                      ) : (
                        // )

                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "14px",
                          }}
                        >
                          검색 결과가 없습니다.
                        </div>
                      )}
                    </>
                  ) : (
                    <Slider
                      dots={false}
                      infinite={true}
                      slidesToScroll={1}
                      slidesToShow={1}
                      afterChange={(current) => {
                        setCurrentSlider(current);
                      }}
                      ref={sliderRef}
                      arrows={false}
                    >
                      {Array.from({ length: 4 }).map((_, index) => (
                        <div key={index}>
                          {!isSearchDataLoading ? (
                            <>
                              {solutionList?.length === 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    width: "100%",
                                    height: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "14px",
                                  }}
                                >
                                  검색 결과가 없습니다.
                                </div>
                              )}
                              {listForm === "gridview" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {postQuestionResponse?.map((question, i) => (
                                    <div
                                      key={question.id + i}
                                      style={{
                                        display: "flex",
                                        flexGrow: 1,
                                        maxWidth: "200px",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <QuestionGridItem
                                        questionData={question}
                                        answerIcon={getAnswerIcons(question)}
                                        clickCard={() => {
                                          setIsSR_detail(true);
                                          setHiddenUrl(-1);
                                          if ([0, 3].includes(currentSlide)) {
                                            navigate(
                                              `/talk/${params["who"]}/${window.localStorage.getItem("isImgSearch") === "true" ? "searchresult" : "post"}/${question?.id}/${question?.sid}/${question?.tid}`
                                            );
                                          } else if ([1, 2].includes(currentSlide)) {
                                            navigate(
                                              `/pdfView/${question?.HIQDB_book_links?.[0]?.bid}?questionId=${question?.id}&prevStep=answer&type=question&prevQuestion=${params.qid}`
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                postQuestionResponse?.map((question, i) =>
                                  listForm === "listview" ? (
                                    // /   / <UiExplain
                                    <QuestionListItem
                                      key={`postSearch${question.id}`}
                                      questionData={question}
                                      answerIcon={getAnswerIcons(question)}
                                      clickCard={() => {
                                        setIsSR_detail(true);
                                        setHiddenUrl(-1);
                                        if ([0, 3].includes(currentSlide)) {
                                          navigate(
                                            `/talk/${params["who"]}/${window.localStorage.getItem("isImgSearch") === "true" ? "searchresult" : "post"}/${question["id"]}/${question["sid"]}/${question["tid"]}`
                                          );
                                        } else if ([1, 2].includes(currentSlide)) {
                                          navigate(
                                            `/pdfView/${question?.HIQDB_book_links?.[0]?.bid}?questionId=${question?.id}&prevStep=answer&type=question&prevQuestion=${params.qid}`
                                          );
                                        }
                                      }}
                                    />
                                  ) : (
                                    <QuestionImageItem
                                      key={`${question}+${i}`}
                                      questionData={question}
                                      answerIcon={getAnswerIcons(question)}
                                      clickCard={() => {
                                        setIsSR_detail(true);
                                        setScrolllKey(question["id"]);
                                        if ([0, 3].includes(currentSlide)) {
                                          navigate(
                                            `/talk/${params["who"]}/${window.localStorage.getItem("isImgSearch") === "true" ? "searchresult" : "post"}/${question["id"]}/${question["sid"]}/${question["tid"]}`
                                          );
                                        } else if ([1, 2].includes(currentSlide)) {
                                          navigate(
                                            `/pdfView/${question?.HIQDB_book_links?.[0]?.bid}?questionId=${question?.id}&prevStep=answer&type=question&prevQuestion=${params.qid}`
                                          );
                                        }
                                      }}
                                    />
                                  )
                                )
                              )}
                            </>
                          ) : (
                            <div style={{ textAlign: "center" }}>
                              <CircularProgress
                                style={{
                                  color: style.common.t_color,
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
            </div>
          )}
        </Basic>
      )}
      {isLoading && <UiLoading text="" />}
      {isLoading_sort && <UiLoading text="문제를 검색중입니다." isTransparent={true} />}
    </>
  );
};

export default Post_Search;
